import { createContext, useState, useMemo, useEffect, lazy, Suspense, useRef, useCallback, CSSProperties } from 'react';
import { Routes, Route } from 'react-router-dom';
import { LinearProgress, PaletteMode, Theme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { IntlProvider } from 'react-intl';
import ReactCanvasConfetti from 'react-canvas-confetti';

import ColorMode from './components/ColorMode';
import darkTheme from './theme';
import './App.scss';
import useJwt, { User } from './hooks/useJwt';

// import useJwtQuery from './queries/useJwtQuery';
// import { useIsFetching, useQueryClient } from 'react-query';

import English from './lang/en.json';

const Home = lazy(() => import('./pages/Home'));
const Users = lazy(() => import('./pages/Users'));
const Blog = lazy(() => import('./templates/blog/Blog'));
const SignIn = lazy(() => import('./templates/sign-in/SignIn'));
const SignUp = lazy(() => import('./templates/sign-up/SignUp'));

export interface ColorModeProps {
  getUser: User | null;
  getJwt: (user?: string, pass?: string) => void;
  resetJwt: () => void;
  toggleColorMode: () => void;
  toggleConfetti: () => void;
}

export const ColorModeContext = createContext<ColorModeProps>({
  getUser: null,
  getJwt: (user?: string, pass?: string) => {
    console.log('user', user, 'pass', pass);
  },
  resetJwt: () => {},
  toggleColorMode: () => {},
  toggleConfetti: () => {},
});

function flattenMessages(nestedMessages: any, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
}

function randomInRange(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
} as CSSProperties;

function getAnimationSettings(originXA: number, originXB: number) {
  return {
    startVelocity: 30,
    spread: 360,
    ticks: 120,
    zIndex: 0,
    particleCount: 120,
    origin: {
      x: randomInRange(originXA, originXB),
      y: Math.random() - 0.2,
    },
  };
}

export default function App() {
  const [user, setUser] = useState<User | null>(null);
  const [mode, setMode] = useState<PaletteMode>('light');

  const { getJwt, resetJwt, getUserToken, getCsrfToken } = useJwt();
  // const { getJwtQuery, resetJwtQuery } = useJwtQuery();

  const refAnimationInstance = useRef<any>(null);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);

  const getAnimationInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const nextTickAnimation = useCallback(() => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current(getAnimationSettings(0.1, 0.3));
      refAnimationInstance.current(getAnimationSettings(0.4, 0.6));
      refAnimationInstance.current(getAnimationSettings(0.7, 0.9));
    }
  }, []);

  const startAnimation = useCallback(() => {
    if (!intervalId) {
      setIntervalId(setInterval(nextTickAnimation, 1000));
    } else {
      setIntervalId(null);
    }
  }, [intervalId, nextTickAnimation]);

  useEffect(() => {
    return () => {
      clearInterval(intervalId as NodeJS.Timeout);
    };
  }, [intervalId]);

  const colorMode = useMemo<ColorModeProps>(
    () => ({
      getUser: user,
      getJwt: () => {
        getJwt((data) => setUser(data));
      },
      resetJwt: () => {
        resetJwt(() => setUser(null));
      },
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
      toggleConfetti: startAnimation,
    }),
    [user, intervalId],
  );

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  useMemo(() => {
    if (prefersDarkMode) {
      if (mode !== 'dark') {
        setMode('dark');
      }
    } else if (mode !== 'light') {
      setMode('light');
    }
  }, [prefersDarkMode]);

  const theme = useMemo<Theme>(() => darkTheme(mode), [mode]);

  // const queryClient = useQueryClient();

  useEffect(() => {
    getUserToken((data) => setUser(data));
    getCsrfToken();
    // setTimeout(() => {
    //   getJwtQuery.mutateAsync().then((data) => {
    //     console.log('refetch');
    //     setUser(data ?? null);
    //   });
    // }, 1000);
    // setTimeout(async () => {
    //   resetJwtQuery.mutateAsync();//.then(() => queryClient.invalidateQueries('jwt'));
    // }, 2000);
  }, []);

  // React.useEffect(
  //   () => {
  //     const data = getJwtQuery.data;
  //     if (data) {
  //       console.log('set user', data);
  //       setUser(data);
  //     }
  //   },
  //   [getJwtQuery.data, getJwtQuery.dataUpdatedAt],
  // );

  // const isFetching = useIsFetching();

  const locale = navigator.language;
  let lang: any;
  if (locale.startsWith('en')) {
    lang = English;
  } else {
    lang = English;
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <IntlProvider locale={locale} messages={flattenMessages(lang)}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ColorMode />
          <ReactCanvasConfetti refConfetti={getAnimationInstance} style={canvasStyles} />
          <Suspense
            fallback={
              <LinearProgress
                className="App-progress"
                variant="indeterminate"
                sx={{ position: 'fixed', top: 0, width: '100%' }}
              />
            }
          >
            <Routes>
              <Route index element={<Home />} />
              <Route path="users/*" element={<Users />} />
              <Route path="blog/*" element={<Blog />} />
              <Route path="signin" element={<SignIn />} />
              <Route path="signup" element={<SignUp />} />
            </Routes>
          </Suspense>
        </ThemeProvider>
      </IntlProvider>
    </ColorModeContext.Provider>
  );
}
