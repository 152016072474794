import { useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import { ColorModeContext } from '../App';

export default function ColorMode() {
  const { toggleColorMode } = useContext(ColorModeContext);
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        p: 1,
      }}
    >
      <Box
        sx={{
          flex: 'auto',
          textAlign: 'initial',
          pl: 2,
        }}
      >
        <Link component={RouterLink} to="/">
          Home
        </Link>{' '}
        <Link component={RouterLink} to="/blog">
          Blog
        </Link>{' '}
        <Link component={RouterLink} to="/users">
          Users
        </Link>{' '}
        <Link component={RouterLink} to="/signin">
          SignIn
        </Link>{' '}
        <Link component={RouterLink} to="/signup">
          SignUp
        </Link>
      </Box>
      <span>{theme.palette.mode} mode</span>
      <IconButton sx={{ ml: 1 }} onClick={toggleColorMode} color="inherit">
        {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </Box>
  );
}
