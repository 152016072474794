import axios from 'axios';
import useNotify from './useNotify';

const API_URL = process.env.NODE_ENV === 'production' ? 'https://jsonplaceholder.typicode.com/api' : '/api';

export interface User {
  user: string;
}

export interface Csrf {
  csrfToken: string;
}

export interface JwtProps {
  getJwt: (callback?: (user: User) => void, user?: string, pass?: string) => void;
  resetJwt: (callback?: () => void) => void;
  getUserToken: (callback?: (user: User) => void) => void;
  getCsrfToken: (callback?: () => void) => void;
}

export default function useJwt(): JwtProps {
  const { showSuccess, showError } = useNotify();

  const getJwt = async (callback?: (user: User) => void, user?: string, pass?: string) => {
    try {
      console.log('user', user, 'pass', pass);
      const { data } = await axios.get<User>(`${API_URL}/jwt/sign`);
      showSuccess('get jwt');
      if (callback) {
        callback(data);
      }
    } catch (err) {
      showError(err as Error);
    }
  };

  const resetJwt = async (callback?: () => void) => {
    try {
      await axios.post(`${API_URL}/jwt/reset`);
      showSuccess('reset jwt');
      if (callback) {
        callback();
      }
    } catch (err) {
      showError(err as Error);
    }
  };

  const getUserToken = async (callback?: (user: User) => void) => {
    try {
      const { data } = await axios.get<User>(`${API_URL}/jwt/info`);
      if (callback) {
        callback(data);
      }
    } catch (err) {
      console.error('user token', err as Error);
    }
  };

  const getCsrfToken = async (callback?: () => void) => {
    try {
      const { data } = await axios.get<Csrf>(`${API_URL}/jwt/csrf`);
      axios.defaults.headers.post['X-CSRF-Token'] = data.csrfToken;
      if (callback) {
        callback();
      }
    } catch (err) {
      console.error('csrf token', err as Error);
    }
  };

  return { getJwt, resetJwt, getUserToken, getCsrfToken };
}
