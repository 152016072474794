import { createTheme, PaletteMode } from '@mui/material';
import { red } from '@mui/material/colors';

const darkTheme = (mode: PaletteMode | undefined) =>
  createTheme({
    palette: {
      primary: {
        light: '#e1ffb1',
        main: '#aed581',
        dark: '#7da453',
      },
      secondary: {
        light: '#e1ffb1',
        main: '#aed581',
        dark: '#7da453',
      },
      error: {
        light: red.A200,
        main: red.A400,
        dark: red.A700,
      },
      mode,
    },
  });

export default darkTheme;
