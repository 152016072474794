import { useSnackbar } from 'notistack';

export interface NotifyProps {
  showSuccess: <T = undefined>(message?: string, data?: T) => T | undefined;
  showError: (error: Error) => undefined;
}

export default function useNotify(): NotifyProps {
  const { enqueueSnackbar } = useSnackbar();

  const showSuccess = <T>(message?: string, data?: T) => {
    enqueueSnackbar(message ?? 'success', {
      variant: 'success',
      anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
    });
    if (data) {
      return data;
    }
    return undefined;
  };

  const showError = (error: Error) => {
    enqueueSnackbar(error.message, {
      variant: 'error',
      anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
    });
    return undefined;
  };

  return { showSuccess, showError };
}
